import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Theme,
  Typography,
  IconButton,
  TablePagination,
  Box,
  SxProps,
  TableContainer
} from '@mui/material'
import {isEmpty, noop} from 'lodash'
import React, {ElementType, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {mergeSx} from '../../utils'

import {Column} from './DataTable.types'
import {DataTableRow} from './DataTableRow'

export interface DataTableProps<T> {
  columns: Column<T>[]
  data: T[]
  keyExtractor?(item: T): string
  onSort?(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, key: string): void
  rowSx?: (item: T, i: number) => SxProps<Theme>
  rowId?(item: T): string
  sortedBy?: string
  sortDirection?: 'asc' | 'desc'
  emptyMessage?: React.ReactNode
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  onRowClick?(event: React.MouseEvent, item: T, i: number): void
  paginationOptions?: PaginationOptions
  rowFooterTemplate?: ElementType<{rowData: T}>
  tableContainerSx?: SxProps<Theme>
  collapseContent?: ElementType<{rowData: T}>
  expandAll?: boolean
  id?: string
  'data-test-id'?: string
}

export const DataTable = <T,>({
  columns,
  data,
  keyExtractor,
  onSort = noop,
  rowSx,
  rowId,
  id,
  sortedBy,
  sortDirection,
  emptyMessage,
  loading,
  onRowClick,
  paginationOptions,
  tableContainerSx,
  expandAll,
  collapseContent: CollapseContent,
  rowFooterTemplate: RowFooterTemplate,
  'data-test-id': dataTestId
}: DataTableProps<T>): JSX.Element => {
  const {t} = useTranslation()
  const isCollapseContent = CollapseContent && !isEmpty(data)
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const toggleRow = useCallback(
    (index: number) => {
      if (expandedRows.includes(index)) {
        setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index))
      } else {
        setExpandedRows([...expandedRows, index])
      }
    },
    [expandedRows]
  )

  return (
    <>
      <TableContainer data-test-id={dataTestId} id={id} sx={tableContainerSx}>
        {Boolean(loading) && <LinearProgress data-test-id="DataTable-loadingIndicator" />}
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {isCollapseContent && <TableCell />}
              {columns.map((column: Column<T>) => {
                const {key, label, width, minWidth, headerElAdditionalProps, columnSx} = column
                const align = column.align || 'left'

                return (
                  <TableCell
                    {...(headerElAdditionalProps || {})}
                    key={key}
                    data-test-id={`DataTable-columnHeader-${key}`}
                    aria-label={`DataTable columnHeader ${key}`}
                    align={align}
                    sortDirection={sortedBy === key ? sortDirection : false}
                    sx={mergeSx(
                      {
                        pr: {xs: 0, sm: 2},
                        pl: {xs: 1.5, sm: 2},
                        ...(width ? {width} : {}),
                        ...(minWidth ? {minWidth} : {})
                      },
                      columnSx
                    )}
                  >
                    {column.sortable ? (
                      <Tooltip
                        title={
                          <Typography variant="caption" color="secondary" display="flex">
                            {t('sortBy')}{' '}
                            <Typography variant="caption" ml={0.5}>
                              {label}
                            </Typography>
                          </Typography>
                        }
                        followCursor
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={sortedBy === key}
                          direction={sortDirection}
                          onClick={(event) => onSort(event, key)}
                          IconComponent={ArrowDownwardIcon}
                        >
                          {label}
                        </TableSortLabel>
                      </Tooltip>
                    ) : (
                      label
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          {data?.length ? (
            !RowFooterTemplate ? (
              <TableBody>
                {data.map((item, i) => {
                  const rowPropStyles = rowSx ? rowSx(item, i) : {}

                  const key = keyExtractor ? keyExtractor(item) : ''
                  return (
                    <DataTableRow<T>
                      collapseContent={CollapseContent}
                      index={i}
                      toggleRow={toggleRow}
                      isOpen={expandAll ? expandAll : expandedRows.includes(i)}
                      key={keyExtractor ? keyExtractor(item) : ''}
                      rowId={rowId}
                      className={`datatable-row-${key}`}
                      item={item}
                      onRowClick={onRowClick}
                      keyExtractor={keyExtractor}
                      columns={columns}
                      rowPropStyles={rowPropStyles}
                      rowIndex={i}
                    />
                  )
                })}
              </TableBody>
            ) : (
              data.map((item, i) => {
                const rowPropStyles = {
                  '& td': {
                    border: 0
                  }
                }

                const key = keyExtractor ? keyExtractor(item) : ''
                return (
                  <TableBody
                    onClick={(event) => onRowClick?.(event, item, i)}
                    key={keyExtractor ? keyExtractor(item) : ''}
                    role="row"
                    sx={rowSx ? rowSx(item, i) : {}}
                  >
                    <DataTableRow<T>
                      collapseContent={CollapseContent}
                      index={i}
                      toggleRow={toggleRow}
                      isOpen={expandAll ? expandAll : expandedRows.includes(i)}
                      key={key}
                      rowId={rowId}
                      className={`datatable-row-${key}`}
                      item={item}
                      keyExtractor={keyExtractor}
                      columns={columns}
                      rowPropStyles={rowPropStyles}
                      rowIndex={i}
                    />
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <RowFooterTemplate rowData={item} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              })
            )
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  data-test-id="DataTable-empty"
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  {emptyMessage}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {paginationOptions && data.length < paginationOptions.totalDataLength && (
        <DataTablePagination paginationOptions={paginationOptions} />
      )}
    </>
  )
}

DataTable.defaultProps = {
  data: [],
  keyExtractor: (item) => ('id' in item ? item.id : String(item)),
  emptyMessage: <Typography variant="caption">no data</Typography>
}

// new exported values

export type PaginationOptions = {
  onPaginationChange: (pageNumber: number, itemsPerPage: number) => void
  totalDataLength: number
  page: number
  rowsPerPage: number
}

interface DataTablePaginationProps {
  paginationOptions: PaginationOptions
}

export const DataTablePagination: React.FC<DataTablePaginationProps> = ({paginationOptions}) => {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (paginationOptions.onPaginationChange) {
      paginationOptions.onPaginationChange(newPage, paginationOptions.rowsPerPage)
    }
  }

  const handleChangeRowsPerPage = (event: {target: {value: string}}) => {
    if (paginationOptions.onPaginationChange) {
      paginationOptions.onPaginationChange(0, parseInt(event.target.value, 10))
    }
  }

  const {t} = useTranslation()

  return (
    <TablePagination
      component={'div'}
      sx={{display: 'flex', justifyContent: 'center', overflow: 'unset'}}
      rowsPerPageOptions={[10, 25, 50, 100]}
      count={paginationOptions.totalDataLength}
      rowsPerPage={paginationOptions.rowsPerPage}
      page={paginationOptions.page}
      SelectProps={{
        inputProps: {'aria-label': 'rows per page'},
        native: true
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage={t('paginationLabel', 'Rows per page:')}
    />
  )
}

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const {count, page, rowsPerPage, onPageChange} = props

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  return (
    <Box flexShrink={0} ml={2.5}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  )
}
