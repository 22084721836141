import {Box, useTheme} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Dates, formatDateLocale, isSameDateMoment} from '../../../common'
import {customThemeConstants} from '../../theme'

import {QuickSelectionItem} from './types'

interface QuickSelectionProps {
  handleItemClick: (dates: Dates, item: QuickSelectionItem) => void
  items?: QuickSelectionItem[]
  selectedItem?: QuickSelectionItem
  dateCustomTemplate?: (option: QuickSelectionItem, language?: string) => string
  timezone: string
}

const defaultCustomTemplate = (item: QuickSelectionItem, language: string) => {
  return isSameDateMoment(item.dates[0], item.dates[1])
    ? item.dates[0] && formatDateLocale(item.dates[0], language)
    : item.dates[0] &&
        item.dates[1] &&
        `${formatDateLocale(item.dates[0], language)} - ${formatDateLocale(
          item.dates[1],
          language
        )}`
}

export const QuickSelection: React.FC<QuickSelectionProps> = ({
  handleItemClick,
  items,
  selectedItem,
  dateCustomTemplate,
  timezone
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const theme = useTheme()
  const today = moment.tz(timezone)
  const oneWeek = today.clone().add(7, 'days')
  const previousWeek = today.clone().subtract(7, 'days')
  const oneMonth = today.clone().add(1, 'months')
  const previousMonth = today.clone().subtract(1, 'months')
  const onSelectionItemClick = (item: QuickSelectionItem) => {
    handleItemClick({startDate: item.dates[0], endDate: item.dates[1] || null}, item)
  }

  const selectionItems: QuickSelectionItem[] = items || [
    {
      title: t('datePicker.todaySelection'),
      dates: [today, today]
    },
    {
      title: t('datePicker.weekSelection'),
      dates: [today, oneWeek]
    },
    {
      title: t('datePicker.monthSelection'),
      dates: [today, oneMonth]
    },
    {
      title: t('datePicker.previousWeekSelection'),
      dates: [previousWeek, today]
    },
    {
      title: t('datePicker.previousMonthSelection'),
      dates: [previousMonth, today]
    }
  ]

  const customOptionTemplate = dateCustomTemplate || defaultCustomTemplate

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      {selectionItems.map((item: QuickSelectionItem, i: number) => {
        const isSelected =
          Boolean(item.id) && (selectedItem?.id === item.id || selectedItem?.title === item.title)
        return (
          <Box
            component="button"
            data-test-id={`quick-selection-button-${i}`}
            key={i}
            sx={[
              {
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                padding: 1.5,
                '&:hover, &:focus': {
                  backgroundColor: customThemeConstants().palette.contentHover
                }
              },
              isSelected && {
                backgroundColor: customThemeConstants().palette.contentSelected,
                '&:hover, &:focus': {
                  backgroundColor: customThemeConstants().palette.contentSelectedHover
                }
              }
            ]}
            data-state={isSelected ? 'selected' : 'unselected'}
            aria-label={`Date Picker ${item.title} button`}
            onClick={() => onSelectionItemClick(item)}
          >
            <Box component="span" sx={{color: theme.palette.text.primary, fontSize: 18}}>
              {item.title}
            </Box>
            <Box component="span" sx={{color: theme.palette.text.secondarySoft}}>
              {customOptionTemplate(item, language)}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
