import {
  EquipmentData,
  RunningTime,
  RunningTimeStoppage,
  RunningTimeType
} from '@hconnect/common/types'
import {getEventStoppageType, isStoppageTypeWithReason} from '@hconnect/common/utils'
import moment from 'moment-timezone'

import {convertMinsToHrsMins} from '../common/utils/datetime'
import {ShiftEvent} from '../types/shiftHandover.types'

export const generateStoppageDefaultData = ({
  runningTime,
  equipment,
  suggestedMainEquipment,
  stoppage
}: {
  runningTime: RunningTime
  equipment: EquipmentData
  suggestedMainEquipment?: EquipmentData
  stoppage?: RunningTimeStoppage
}): Partial<ShiftEvent> => {
  const stoppageType = getEventStoppageType(equipment)

  const stoppageStart = stoppage?.start ?? runningTime.beginTechnical
  const stoppageEnd = stoppage?.end ?? runningTime.endTechnical

  return {
    stoppageStart,
    stoppageType,
    pxTrendTag: equipment.id,
    pxTrendStoppage: runningTime,
    ...(stoppageEnd ? {stoppageEnd} : {}),
    ...(suggestedMainEquipment ? {mainEquipment: suggestedMainEquipment} : {}),
    ...(isStoppageTypeWithReason(stoppageType) ? {stoppageReason: undefined} : {})
  }
}

export const runningTimesToHrsMins = (runningTimes: RunningTime[] = []) => {
  return convertMinsToHrsMins(
    runningTimes
      .filter((rt: RunningTime) => rt.runningTimeType === RunningTimeType.RUNNING)
      .reduce(
        (sum: number, current: RunningTime) =>
          sum + moment(current.end).diff(moment(current.begin), 'minutes'),
        0
      )
  )
}
