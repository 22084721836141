import {Typography, TypographyProps} from '@mui/material'
import React from 'react'

import {customThemeConstants} from '../../theme'

export const PageTitle: React.FC<TypographyProps> = (props) => (
  <Typography
    variant="h1"
    ml={customThemeConstants().defaultGridSpacing}
    mb={customThemeConstants().defaultGridSpacing}
    {...props}
  />
)
