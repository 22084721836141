import {Typography, TypographyProps} from '@mui/material'
import React from 'react'

import {customThemeConstants} from '../../theme'
import {mergeSx} from '../../utils'

export type TextContrast = 'high' | 'low'

export const TextOnBackground: React.FC<
  TypographyProps & {contrast?: TextContrast; highlightOnHover?: boolean}
> = ({contrast = 'high', highlightOnHover = false, sx, ...rest}) => {
  const opacity = contrast === 'high' ? 1 : customThemeConstants().labelOpacity
  const textSx = highlightOnHover
    ? {
        opacity,
        '&:hover': {
          opacity: customThemeConstants().labelOpacityHover
        }
      }
    : {opacity}

  return <Typography color="primary.contrastText" sx={mergeSx(textSx, sx)} {...rest} />
}
