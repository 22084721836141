import {ManualKpiToUpdate} from '@hconnect/common/types'
import {COLUMN_SX} from '@hconnect/common/utils'
import {NumericTextField, Column} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {TFunction} from 'i18next'
import {Control, Controller} from 'react-hook-form'

import {MenuButton, MenuConfig} from '../components/common/Menu'
import {KpiFormData, KpiFormFields} from '../types/kpi.types'
import {rangeValidator} from '../validators'

export const mapFormDataToKpisToUpdate = (
  data: KpiFormData,
  manualKpiList: ManualKpiToUpdate[]
): ManualKpiToUpdate[] => {
  const manualKpisToUpdate: ManualKpiToUpdate[] = []
  Object.entries(data).forEach(([processStage, kpisByName]) => {
    Object.entries(kpisByName).map(([name, kpi]) => {
      const kpiToUpdate = manualKpiList.find(
        (kpi) => kpi.processStage === processStage && kpi.name === name
      )
      if (kpiToUpdate) {
        const newKpiData: KpiFormFields = {
          actualValue: kpi.actualValue !== 0 && !kpi.actualValue ? undefined : kpi.actualValue,
          plannedValue: kpi.plannedValue !== 0 && !kpi.plannedValue ? undefined : kpi.plannedValue
        }
        manualKpisToUpdate.push({...kpiToUpdate, ...newKpiData})
      }
    })
  })

  return manualKpisToUpdate
}

const isUnitPercent = (unit: string): boolean => unit === '%'

export const generateEditColumns = (
  t: TFunction,
  control: Control<KpiFormData>,
  removeManualKpi: (processStageId: string, name: string) => void
): Column<ManualKpiToUpdate>[] => [
  {
    key: 'name',
    label: t('performance.label.kpi'),
    columnSx: COLUMN_SX
  },
  {
    key: 'planned',
    label: t('performance.label.planned'),
    width: 105,
    columnSx: COLUMN_SX,
    customTemplate: ({plannedValue, processStage, name, unit}) => (
      <Controller
        name={`${processStage}.${name}.plannedValue`}
        control={control}
        defaultValue={plannedValue}
        rules={isUnitPercent(unit) ? rangeValidator(t, [0, 100]) : undefined}
        render={({field: {ref, value, onChange}}) => (
          <NumericTextField
            inputRef={ref}
            value={value}
            onChange={onChange}
            onBlur={() => {
              if (isUnitPercent(unit) && value) {
                if (value < 0) {
                  onChange(0)
                } else if (value > 100) {
                  onChange(100)
                }
              }
            }}
            hiddenLabel={true}
            data-test-id={`kpi-planned-input-${processStage}-${name}`}
            variant="filled"
            size="small"
            inputProps={isUnitPercent(unit) ? {min: '0', max: '100'} : undefined}
          />
        )}
      />
    )
  },
  {
    key: 'actual',
    label: t('performance.label.actual'),
    width: 105,
    columnSx: COLUMN_SX,
    customTemplate: ({actualValue, processStage, name, unit}) => (
      <Controller
        name={`${processStage}.${name}.actualValue`}
        control={control}
        defaultValue={actualValue}
        rules={isUnitPercent(unit) ? rangeValidator(t, [0, 100]) : undefined}
        render={({field: {ref, value, onChange}}) => (
          <NumericTextField
            inputRef={ref}
            value={value}
            onChange={onChange}
            onBlur={() => {
              if (isUnitPercent(unit) && value) {
                if (value < 0) {
                  onChange(0)
                } else if (value > 100) {
                  onChange(100)
                }
              }
            }}
            hiddenLabel={true}
            data-test-id={`kpi-actual-input-${processStage}-${name}`}
            variant="filled"
            size="small"
            inputProps={isUnitPercent(unit) ? {min: '0', max: '100'} : undefined}
          />
        )}
      />
    )
  },
  {
    key: 'unit',
    label: t('performance.label.unit'),
    columnSx: COLUMN_SX,
    width: 40
  },
  {
    key: 'actions',
    columnSx: COLUMN_SX,
    width: 20,
    customTemplate: ({processStage, name}) => {
      const menuConfig: MenuConfig = [
        {
          dataTestId: 'kpi-delete-button',
          label: t('action.delete'),
          onClick: () => {
            removeManualKpi(processStage, name)
          },
          icon: <DeleteOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
        }
      ]

      return (
        <MenuButton
          config={menuConfig}
          menuDataTestId={`manual-kpi-more-button-${processStage}-${name}`}
          data-test-id={`manual-kpi-more-button-${processStage}-${name}`}
        />
      )
    }
  }
]
