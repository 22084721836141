import {WheelEvent, useCallback} from 'react'

import {HPTextField, type HPTextFieldProps} from '../HPTextField'

export function NumericTextField(props: Omit<HPTextFieldProps, 'type'>) {
  const {onWheel: originalOnWheel} = props
  const onWheel = useCallback(
    (e: WheelEvent<HTMLDivElement>) => {
      // force blur event which would disable modyfying element value via mouse wheel
      ;(e.target as HTMLElement)?.blur()
      if (originalOnWheel) {
        originalOnWheel(e)
      }
    },
    [originalOnWheel]
  )
  return <HPTextField {...props} type="number" onWheel={onWheel} />
}
