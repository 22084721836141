import {Box, SxProps, Theme} from '@mui/material'
import React from 'react'

import {useShellContext} from '../../common'
import {customThemeConstants} from '../theme'

import {Content} from './Content'

type Props = {
  sx?: SxProps<Theme>
  isVisible: boolean
  children: React.ReactNode
  id?: string
  'data-test-id'?: string
}

export enum OverlayState {
  Open = 'open',
  Closed = 'closed'
}

export const Overlay: React.FC<Props> = ({
  sx = [],
  isVisible = false,
  children,
  id,
  'data-test-id': dataTestId = 'overlay'
}) => {
  const {compact, scrolled} = useShellContext()

  return (
    <Content
      sx={[
        {
          position: 'fixed',
          top: compact || scrolled ? 50 : 66,
          bottom: 0,
          left: 0,
          right: 0,
          overflowY: 'auto',
          opacity: isVisible ? 1 : 0,
          pointerEvents: isVisible ? 'auto' : 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          transition: 'opacity, top',
          transitionDuration: '.3s',
          transitionTimingFunction: 'ease',
          background: customThemeConstants().palette.backgroundGradient,

          stretchedOverlay: {
            top: 50
          }
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      data-test-id={dataTestId}
      id={id}
      data-value={isVisible ? OverlayState.Open : OverlayState.Closed}
    >
      <Box
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          paddingLeft: '5%',
          paddingRight: '5%'
        }}
      >
        {children}
      </Box>
    </Content>
  )
}
