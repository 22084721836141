import {
  ProductionVolume,
  ProductionVolumeDto
} from '@hconnect/common/components/productionContainer'
import {
  Category,
  EquipmentData,
  EventType,
  Priority,
  Stoppage,
  FilterTimeRange,
  StoppageType,
  User,
  AttachmentFile,
  Attachment,
  TaskRepetition,
  EventTypeInterchangeable,
  InforWorkOrder,
  StoppageDto,
  TaskChecklistsOption,
  StatusWithNoneAndCancellationAndApproverAndWorkOrderStates,
  SharedEventStructureDto,
  Assignees,
  ShiftEventTaskDto,
  ShiftEventCommentDto as CommentDto,
  ShiftEventComment as Comment
} from '@hconnect/common/types'
import {union} from 'lodash'
import {Moment} from 'moment-timezone'

import {Iso8601} from './atomic.types'
import {SapData, SapDataDto} from './maintenanceNotification.types'

export type {Iso8601, Uuid} from './atomic.types'
export type {Comment, CommentDto}

export enum EventEditModeEnum {
  SINGLE = 'single',
  SINGLE_IN_SERIES = 'singleInSeries',
  SERIES = 'series'
}

export type EventEditModes = 'single' | 'singleInSeries' | 'series'

export enum PmNotificationStatus {
  Unknown = 'unknown',
  OrderAssigned = 'orderAssigned',
  OutstandingNotification = 'outstandingNotification',
  Deleted = 'deleted',
  InProgress = 'inProgress',
  Completed = 'completed'
}
export enum WorkOrderStatus {
  Unknown = 'unknown',
  Closed = 'closed',
  Released = 'released',
  Completed = 'completed',
  Created = 'created'
}

export const eventStatusListWorkOrder = ['workOrderCreated'] as const

// Generic event status types. Used in Malfunction, Environment, Quality, HealthAndSafety
export const statusList = ['pending', 'ongoing', 'done'] as const
// Status used only in Tasks
export const statusListWithCancellationOption = ['pending', 'ongoing', 'done', 'cancelled'] as const
// Status used in Information and Idea
export const statusListWithNoneOption = ['pending', 'ongoing', 'done', 'none'] as const
// Status used for  ParameterChange along with the statusList
export const statusListApprovalStates = ['approved', 'rejected', 'waitingForApproval'] as const

/**
 * States that are used on the Work order and notifications tab of events page
 * These are the states that are used to filter the work-orders of notifications
 */
export const pmNotificationStatusListApprovalStates = Object.values(PmNotificationStatus)
export const workOrderStatusListApprovalStates = Object.values(WorkOrderStatus)

// Approver states are used in the parameter change event type
export const statusListWithApproverStates = [...statusList, ...statusListApprovalStates]

// All states that an event can have. This is used on events page 'Status' filter
export const statusListWithAllStates: StatusWithNoneAndCancellationAndApproverAndWorkOrderStates[] =
  union(
    statusListWithCancellationOption,
    statusListWithNoneOption,
    statusListWithApproverStates,
    eventStatusListWorkOrder
  )

export type Status = (typeof statusList)[number]
export type StatusWithCancellation = (typeof statusListWithCancellationOption)[number]
export type StatusWithNone = (typeof statusListWithNoneOption)[number]
export type StatusWithNoneAndCancellation = 'none' | 'pending' | 'ongoing' | 'done' | 'cancelled'
export type StatusListWithApproverStates = (typeof statusListWithApproverStates)[number]
export type WorkOrderEventStatus = (typeof eventStatusListWorkOrder)[number]

export const eventTypeToStatusMapping: {
  [key in EventTypeInterchangeable]: readonly (
    | Status
    | StatusWithNone
    | StatusWithNoneAndCancellation
    | StatusListWithApproverStates
  )[]
} = {
  malfunction: statusList,
  information: statusListWithNoneOption,
  idea: statusListWithNoneOption,
  environment: statusList,
  quality: statusList,
  healthAndSafety: statusList,
  task: statusListWithCancellationOption,
  parameterChange: statusListWithApproverStates
}

// TODO find a better name then "info" (and change src//components/Info.tsx as well)
// TODO: pick this from common packages/common/types/shiftHandover.types.ts
export interface InfoDto {
  // username
  createdBy: User
  modifiedBy: string
  created: Iso8601
  lastModified: Iso8601
  lastModifiedStatus?: Iso8601
}
export interface Info {
  // username
  createdBy: User
  modifiedBy: string
  created: Moment
  lastModified: Moment
  lastModifiedStatus?: Moment
}

export interface Id {
  id: string
}

export type ParameterChangeData = {
  currentValue?: string
  newValue?: string
  needsApproval: boolean
  checkedBy?: User
  approvers?: User[]
}

export type Schedule = {
  startDate: Moment
  endDate?: Moment
}
type SharedEventStructure = {
  category: Category
  description: string
  title: string
  commentCount: number

  // optional
  isHighlighted?: boolean
  equipment?: EquipmentData
  mainEquipment?: EquipmentData
  processStage?: string
  priority?: Priority
  schedule?: Schedule
  inforWorkOrder?: InforWorkOrder
}

const shiftEventKeys: (keyof Partial<ShiftEvent>)[] = [
  'attachments',
  'id',
  'createdBy',
  'modifiedBy',
  'created',
  'lastModified',
  'lastModifiedStatus',
  'status',
  'category',
  'description',
  'title',
  'equipment',
  'mainEquipment',
  'isHighlighted',
  'processStage',
  'priority',
  'eventType'
]

export type ShiftEventKeys = (typeof shiftEventKeys)[number]

export const sharedEventStructureKeys: (keyof SharedEventStructure | ShiftEventKeys)[] = [
  'schedule',
  ...shiftEventKeys
]

export const taskKeys: (keyof Partial<Task>)[] = [
  'assignees',
  'dueDate',
  'repetitionInfo',
  'shiftId',
  'doNotStartBefore',
  'toggleAssigneeNotification',
  ...(shiftEventKeys as (keyof Task)[])
]

export const parameterChangeKeys: (keyof ParameterChange)[] = [
  'parameterChange',
  ...(shiftEventKeys as (keyof ParameterChange)[])
]

export type EventStatus<T> = T | WorkOrderEventStatus

export type Malfunction = SharedEventStructure & {
  eventType: 'malfunction'
  status: EventStatus<StatusWithCancellation>
}
export type Information = SharedEventStructure & {
  eventType: 'information'
  status: EventStatus<StatusWithNone>
}
export type Idea = SharedEventStructure & {
  eventType: 'idea'
  status: EventStatus<StatusWithNone>
}
export type HealthAndSafety = SharedEventStructure & {
  eventType: 'healthAndSafety'
  status: EventStatus<StatusWithCancellation>
}
export type Environment = SharedEventStructure & {
  eventType: 'environment'
  status: EventStatus<StatusWithCancellation>
}
export type ParameterChange = SharedEventStructure & {
  eventType: 'parameterChange'
  status: EventStatus<StatusListWithApproverStates>
  parameterChange?: ParameterChangeData
}
export type Quality = SharedEventStructure & {
  eventType: 'quality'
  status: EventStatus<StatusWithCancellation>
}
type MalfunctionDto = SharedEventStructureDto & {
  eventType: 'malfunction'
  status: EventStatus<StatusWithCancellation>
}
type InformationDto = SharedEventStructureDto & {
  eventType: 'information'
  status: EventStatus<StatusWithNone>
}
type IdeaDto = SharedEventStructureDto & {
  eventType: 'idea'
  status: EventStatus<StatusWithNone>
}
type HealthAndSafetyDto = SharedEventStructureDto & {
  eventType: 'healthAndSafety'
  status: EventStatus<StatusWithCancellation>
}
type EnvironmentDto = SharedEventStructureDto & {
  eventType: 'environment'
  status: EventStatus<StatusWithCancellation>
}
type ParameterChangeDto = SharedEventStructureDto & {
  eventType: 'parameterChange'
  status: EventStatus<StatusListWithApproverStates>
  parameterChange?: ParameterChangeData
}
type QualityDto = SharedEventStructureDto & {
  eventType: 'quality'
  status: EventStatus<StatusWithCancellation>
}

type ProductionVolumesResultDto = {
  success: boolean
  errorMessage?: string
  productionVolumes: ProductionVolumeDto[]
}
type ProductionVolumesResult = {
  success: boolean
  errorMessage?: string
  productionVolumes: ProductionVolume[]
}

export type SummaryPageDataDto = {
  productionVolumesResult: ProductionVolumesResultDto
}
export type SummaryPageData = {
  productionVolumesResult: ProductionVolumesResult
}

export type SummaryPageDataSearchCriteria = {
  startDate: Iso8601 | null
  endDate: Iso8601 | null
}

export type Task = Omit<SharedEventStructure, 'schedule'> &
  Assignees & {
    dueDate: Moment
    eventType: 'task'
    status: StatusWithCancellation

    // optional
    toggleAssigneeNotification?: boolean
    repetitionInfo?: TaskRepetition | null
    shiftId?: number | null // TODO double check
    doNotStartBefore?: Moment
    isChecklistsCreationFailed?: boolean
  }

export type BaseEventDto =
  | MalfunctionDto
  | InformationDto
  | IdeaDto
  | HealthAndSafetyDto
  | EnvironmentDto
  | ParameterChangeDto
  | ShiftEventTaskDto
  | StoppageDto
  | QualityDto

export type BaseEvent =
  | Malfunction
  | Information
  | Idea
  | HealthAndSafety
  | Environment
  | ParameterChange
  | Task
  | Stoppage
  | Quality

export type ShiftEventDto = BaseEventDto &
  Id &
  InfoDto &
  SapDataDto & {
    attachments?: Attachment[]
  }
export type ShiftEvent = BaseEvent &
  Id &
  Info &
  SapData & {
    attachments?: Attachment[]
  }

export type EventCreate = BaseEvent & {
  attachments?: AttachmentFile[]
  // used for splitting a stoppage
  splitOptions?: {
    sourceStoppageId: string
    splitPoint: Iso8601
  }
} & {
  checklists?: TaskChecklistsOption[]
}
export type EventCreateDto = BaseEventDto & {
  attachments?: AttachmentFile[]
}

export type EventUpdate = BaseEvent &
  Id & {
    lastModified: Moment
    attachmentsToRemove: string[]
    newAttachments?: AttachmentFile[]
  }
export type EventUpdateDto = BaseEventDto &
  Id & {
    lastModified: Iso8601
    attachmentsToRemove: string[]
    newAttachments?: AttachmentFile[]
  }

// TODO better naming (open for suggestions)
export type ShiftEventAndUpdateEvent = ShiftEvent & EventUpdate

export interface ShiftEventCommentCreate {
  description: string
}

export type ShiftCommentCreate = ShiftEventCommentCreate & {submittedFor: Iso8601}

/* app-config */

export type FeatureFlagKey =
  | 'movieAttachment'
  | 'sapMaintenanceNotification'
  | 'inforMaintenanceNotification'
  | 'productionVolumes'
  | 'notifications'
  | 'termsAndConditions'
  | 'allProcessStagesSummary'
  | 'expertSystem'
  | 'checklists'
  | 'enableSplitStoppage'
  | 'mainEquipmentList'

/* search / Filter */

export type StoppageCodesFilter = {
  type: StoppageType
  codes: string[]
}

// this is what the yml defines
// export type EventSearchResult = {
//   events: ShiftEvent[]
//   currentPage: number
//   totalPages: number
// }
// and this what the backend returns
export type EventSearchResult = {
  currentPage: number
  events: ShiftEvent[]
  totalItemCount: number
  totalPages: number
}
export type EventSearchDto = {
  currentPage: number
  events: ShiftEventDto[]
  totalItemCount: number
  totalPages: number
}

export enum SortBy {
  Created = 'created',
  Details = 'details',
  EventType = 'eventType',
  Title = 'title',
  EquipmentNo = 'equipmentNo',
  MainEquipmentNo = 'mainEquipmentNo',
  Category = 'category',
  ProcessStage = 'processStage',
  MaintenanceNotificationId = 'maintenanceNotificationId',
  Status = 'status',
  LastModified = 'lastModified'
}

export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending'
}

export type EventSearchCriteria = {
  eventType?: EventType[]
  category?: Category[]
  processStage?: string[]
  status?: StatusWithNoneAndCancellationAndApproverAndWorkOrderStates[]
  priority?: Priority[]
  stoppageCodesFilter?: StoppageCodesFilter[]

  timeRange: FilterTimeRange
  sortBy?: SortBy
  sortOrder?: SortOrder
  itemsPerPage?: number
  pageNumber?: number
  equipment?: EquipmentData
  mainEquipment?: EquipmentData
  freeText?: string
  maintenanceNotificationExists?: boolean
}

/* conflict on update */

export type ConflictingProperty<T> = {
  name: string
  serverValue: T
  yourValue: T
  delta: number
}

export type ExecutionResultConflict = {
  lastModifiedAt: string
  lastModifiedBy: string
  conflictingProperties: ConflictingProperty<Record<string, unknown>>[]
}

/** Known DF permissions related to the Cockpit */
export const Permissions = [
  'VIEW_PLANT_EVENT',
  'CREATE_PLANT_EVENT',
  'VIEW_PLANT_TASK',
  'CREATE_PLANT_TASK',
  'VIEW_SHIFT_REPORT',
  'VIEW_SHIFT_REPORT',
  'EDIT_PLANT_EVENTS',
  'DELETE_PLANT_EVENTS',
  'EDIT_PLANT_TASK',
  'DELETE_PLANT_TASKS',
  'ADD_DOCUMENTS',
  'VIEW_DOCUMENTS',
  'DELETE_DOCUMENTS',
  'ADD_PLANT_EVENT_COMMENTS',
  'EDIT_PLANT_EVENT_COMMENTS',
  'VIEW_PLANT_EVENT_COMMENTS',
  'DELETE_PLANT_EVENT_COMMENTS',
  'DOWNLOAD_SHIFTREPORT',
  'CHANGE_PLANT_EVENT_STATUS',
  'ASSIGN_PLANT_TASK',
  'REASSIGN_PLANT_TASK',
  'FILTER_PLANT_EVENT',
  'SEARCH_PLANT_EVENT',
  'SEND_PLANT_EVENT_TO_SAP',
  'CREATE_SAP_REQUESTS'
] as const
export type CockpitPermission = (typeof Permissions)[number]

// TODO: check if we can remove these reliabilityRoles.
// If not and relevant, add to documentation.
/** Known DF Roles related to RCFA */
export const reliabilityRolls = ['RELIABILITY_ENGINEER', 'RELIABILITY_CONTRIBUTOR'] as const
/** Known DF Roles related to Cockpit */
export const roles = [
  'SHIFT_LEADER',
  'SHIFT_WORKER',
  'SHIFT_REPORT_READ_ONLY',
  ...reliabilityRolls
] as const
export type Role = (typeof roles)[number]

export enum WorkOrderSortBy {
  Title = 'title',
  Equipment = 'equipment',
  Status = 'status'
}

export type MainEquipmentsDto = Required<Pick<EquipmentData, 'idType' | 'id' | 'text'>>[]
